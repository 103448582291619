/** @jsx jsx */
import { jsx } from "theme-ui";
import DLWLayout from "../../../layouts/dlw-fixed";

import OverseasVisitorsAdmin from "../../../components/AshramOffice/OverseasVisitors/admin";

function OverseasVisitors() {
  return (
    <DLWLayout sx={{ bg: "white", pb: 4 }}>
      <OverseasVisitorsAdmin />
    </DLWLayout>
  );
}

export default OverseasVisitors;
