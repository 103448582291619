/** @jsx jsx */
import { jsx, Text } from "theme-ui";
import { containerStyles } from "sites-common/utils/fabricStyles";
import {
  ActionButton,
  DefaultButton,
  Pivot,
  PivotItem,
  Stack,
  StackItem,
} from "office-ui-fabric-react";

import DynamicForm from "@heartfulnessinstitute/react-hfn-forms/dist/DynamicForm";
import { useCallback, useEffect, useState } from "react";
import { noop } from "sites-common/utils/lodash";
import useMyBoolean from "sites-common/hooks/useMyBoolean";
import ConfirmButton from "gatsby-plugin-hfn-profile/components/ConfirmButton";
import {
  PIVOT_CHECKEDIN,
  PIVOT_CHECKEDOUT,
  PIVOT_RECENT,
  PIVOT_SEARCH,
  STAY_CHECKEDIN,
  STAY_CHECKEDOUT,
  STAY_NONE,
} from "../../OverseasSecureVault/constants";
import MyStayRecords from "../../OverseasSecureVault/MyStayRecords";

const submitButton = (
  <DefaultButton
    type="submit"
    iconProps={{ iconName: "Search" }}
    text="Search"
  />
);

const queryFields = [
  {
    name: "key",
    label: "field",
    type: "select",
    options: [
      { name: "full_name", label: "Name" },
      // { name: "mobile", label: "Mobile" },
      // { name: "id", label: "Abhyasi ID" },
      // { name: "email", label: "Email" },
      { name: "passport_num", label: "Passport Number" },
    ],
  },
  { name: "value", label: "equals", props: {} },
];

const formLayout = (
  renderField,
  renderErrors,
  renderLoading,
  renderSubmitBtn,
  renderDiscardBtn
) => (
  <Stack horizontal verticalAlign="end">
    <StackItem sx={{ width: 120 }}>{renderField("key")}</StackItem>
    <StackItem sx={{ width: 200 }}>{renderField("value")}</StackItem>
    <StackItem sx={{ maxWidth: 50 }}>
      {renderSubmitBtn} {renderDiscardBtn} {renderErrors} {renderLoading}
    </StackItem>
  </Stack>
);
function OverseasVisitorsAdmin() {
  const [pivot, setPivot] = useState(PIVOT_CHECKEDIN);
  const [stay, setStay] = useState(null);

  const [mistakes, { toggle }] = useMyBoolean(false);

  const onPivotClick = useCallback(
    (item) => {
      if (item) {
        setPivot(item.props.itemKey);
      }
    },
    [setPivot]
  );
  const onSubmit = useCallback((p, s /* ,f */) => {
    if (!!p.key && !!p.value) {
      setStay({
        queryParams: {
          where: [p.key, "==", p.value],
        },
      });
    }

    s();
  }, []);

  useEffect(() => {
    if (pivot === PIVOT_CHECKEDIN) {
      setStay({
        queryParams: {
          where: ["stay_status", "==", STAY_CHECKEDIN],
        },
      });
    } else if (pivot === PIVOT_CHECKEDOUT) {
      setStay({
        initialLimit: 20,
        queryParams: {
          where: ["stay_status", "==", STAY_CHECKEDOUT],
        },
      });
    } else if (pivot === PIVOT_RECENT) {
      setStay({
        initialLimit: 20,
        queryParams: {
          where: ["stay_status", "==", STAY_NONE],
        },
      });
    } else if (pivot === PIVOT_SEARCH) {
      setStay(null);
    } else {
      setStay(null);
    }
  }, [pivot]);

  return (
    <div>
      <Text style={containerStyles.flexcenter} variant="header">
        FRRO - Overseas Visitors
      </Text>
      <Text style={containerStyles.flexcenter} variant="subtitle">
        Admin Console
      </Text>

      {/*
       0. Provide Query Fields ( name / mobile / email / abhyasi id / passport num )


      1. add compound index for stay_status && arrival asc -- to support order by on arrival

      2. expiries - compute 30 days before



      */}

      <Pivot sx={{ my: 4 }} selectedKey={pivot} onLinkClick={onPivotClick}>
        <PivotItem headerText="Currently Staying" itemKey={PIVOT_CHECKEDIN} />
        <PivotItem headerText="Checked-Out" itemKey={PIVOT_CHECKEDOUT} />
        <PivotItem headerText="Visitors coming soon" itemKey={PIVOT_RECENT} />
        <PivotItem headerText="Search" itemKey={PIVOT_SEARCH}>
          <div sx={{ p: 3, backgroundColor: "lightsalmon" }}>
            <DynamicForm
              formLayout={formLayout}
              formFields={queryFields}
              defaultValues={{ key: "full_name", value: "" }}
              onSubmit={onSubmit}
              onDiscard={noop}
              hideDiscard
              buttons={{ submitButton }}
            />
          </div>
        </PivotItem>
      </Pivot>
      {!!stay && (
        <MyStayRecords
          key={stay.initialLimit || "11"}
          admin
          initialLimit={stay.initialLimit}
          queryParams={stay.queryParams}
          pivot={pivot}
          mistakes={mistakes}
        />
      )}

      {
        <div sx={{ p: 3, bg: mistakes ? "red" : "transparent", mt: 5 }}>
          <Stack horizontal horizontalAlign="right">
            {mistakes ? (
              <DefaultButton
                checked={mistakes}
                text="Exit Mistake Correction Mode"
                iconProps={{ iconName: "Repair" }}
                onClick={toggle}
              />
            ) : (
              <ConfirmButton
                title="Entering Mistakes Correction Mode"
                message="You can undo checkin/checkout operations or reset ack number in this mode. Are you sure you want to continue?"
                onClick={toggle}
              >
                <ActionButton
                  iconProps={{ iconName: "Repair" }}
                  checked={mistakes}
                  text="Enter Mistake Correction Mode"
                />
              </ConfirmButton>
            )}
          </Stack>
        </div>
      }
    </div>
  );
}

OverseasVisitorsAdmin.propTypes = {};

export default OverseasVisitorsAdmin;
